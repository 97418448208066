import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/app/globals.css");
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/components/AuthClient/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/components/LayoutClient/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/components/ui/ThemeProvider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/containers/TaskManager/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/contexts/KVSContext.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/contexts/RolesContext.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/contexts/TimesheetContext.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/phpreaction-frontend-punch-react/phpreaction-frontend-punch-react/src/contexts/UserContext.tsx")